<template>
    <div class="w-screen h-full flex flex-col justify-center items-center text-white">
        <div id="video-background-wrapper" class="video-background-wrapper">
            <div class="fullscreen-video-wrapper">
                <div class="overlay-loader"></div>
                <div class="vimeo-wrapper">
                    <video :src="video_src" autoplay loop muted playsinline>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
        <div class="z-50 flex flex-col items-center justify-center px-6 py-8 mx-auto md:min-h-screen pt:mt-0">
            <a v-bind:href="'signed24'" class="flex items-center mb-6 text-2xl font-semibold text-white">
                <img class="h-12 mr-2" :src="event_logo" alt="Logo">
            </a>
            <div>
                <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                        <h2
                            class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
                            Schrijf je in voor de Ampco Flashlight Seminars op maandag of dinsdag!
                        </h2>
                        <p
                            class="text-sm font-medium leading-tight tracking-tight text-center text-gray-700 md:text-md dark:text-gray-300">
                            Meld je aan en laat ons weten welke dag jouw voorkeur heeft. We informeren je binnenkort over de beschikbare sessies.</p>
                        <div v-if="formResponse">
                            <div class="">
                                <FwbAlert type="info" title="" class="mb-2">
                                    <p class="text-center border border-blue-500 rounded-lg py-2.5 px-4">{{
                                        formLabels.registered_text }}</p>
                                </FwbAlert>
                            </div>
                        </div>
                        <form v-else class="space-y-4 md:space-y-6" @submit.prevent="formRequest">
                            <div class="grid gap-6 mb-6 md:grid-cols-2">
                                <div>
                                    <FormInput id="first_name" :label="formLabels.first_name"
                                        v-model="formState.first_name" :error="validationErrors.first_name"
                                        placeholder="John" />
                                </div>
                                <div>
                                    <FormInput id="last_name" :label="formLabels.last_name"
                                        v-model="formState.last_name" :error="validationErrors.last_name"
                                        placeholder="Doe" />
                                </div>
                            </div>

                            <div>
                                <FormInput id="phone" :label="formLabels.phone" v-model="formState.phone"
                                    :error="validationErrors.phone" :explanation_first="formLabels.phone_sub_text"
                                    :explanation_second="formLabels.phone_under_sub_text" placeholder="+31688888888"
                                    type="tel" />
                            </div>
                            <div>
                                <FormInput id="email" :label="formLabels.email" v-model="formState.email"
                                    :error="validationErrors.email" :explanation_first="formLabels.email_sub_text"
                                    placeholder="name@company.com" type="text" />
                            </div>
                            <div>
                                <FormTicketInput id="tickets" :label="formLabels.tickets"
                                    v-model="formState.number_of_tickets" :max_tickets="1" />
                            </div>
                            <div>
                                <DropdownSelect
                                    label="Kies de dag"
                                    :error="validationErrors.version"
                                    :options="versionOptions"
                                    v-model="formState.version"
                                    />
                            </div>
                            <div>
                                <TextFieldInput
                                    label="Bij welke organisatie ben je werkzaam?"
                                    :error="validationErrors.comments"
                                    v-model="formState.comments"
                                    explanation_first="Vermeld hier de naam van het bedrijf of de organisatie waarmee je verbonden bent."
                                />
                            </div>
                            <div class="flex items-start">
                                <div class="flex items-center mr-4">
                                    <input v-model="isCheckboxChecked" id="terms" aria-describedby="terms"
                                        type="checkbox"
                                        class="accent-purple-600 w-4 h-4 text-purple-500 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        required="">
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="terms" class="font-light text-gray-500 dark:text-gray-300">{{
                                        formLabels.legal_text_one }}<a
                                            class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                            :href="formLabels.legal_link">{{ formLabels.legal_text_two }}</a>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <button class="w-full" type="submit" :disabled="!isCheckboxChecked" :class="[
                                    'text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center',
                                    !isCheckboxChecked ? 'opacity-50 cursor-not-allowed' : ''
                                ]" :style="{
                                    background: `linear-gradient(to right, #283484, #3D73C3)`,
                                    color: 'white',
                                    padding: '0.5rem 1rem',
                                    borderRadius: '0.25rem'
                                }">{{ formLabels.submit_text }}</button>
                            </div>
                        </form>
                        <div class="flex !mt-6">
                            <a type="button" href="https://livecrowd.com/support/" target="_blank"
                                class="w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded text-sm py-2.5 text-center">Neem
                                contact op met Livecrowd</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import FormInput from './FormInput.vue';
import FormTicketInput from './FormTicketInput.vue';
import DropdownSelect from './DropdownSelect.vue'
import TextFieldInput from './TextFieldInput.vue';
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { initFlowbite } from 'flowbite'
import { FwbAlert } from 'flowbite-vue'
import { useHead } from '@vueuse/head'
import { z } from 'zod';

const isCheckboxChecked = ref(false);
const formResponse = ref("");
const baseUrl = ref(import.meta.env.VITE_BASE_URL);

const event_logo = "https://s3.eu-central-1.amazonaws.com/cdn.crafture.com/guestticket/logos/AF+1+regel.png"
const video_src = "https://s3.eu-central-1.amazonaws.com/cdn.crafture.com/guestticket/bg+video/CRAFTURE_UPI_AF_BGVIDEO.mp4"
const favicon = "https://guestticket.crafture.com/media/client_logos/Crafture/CRAFTURE_LOGO_BEELDMERK_wit_op_zwart.jpg"
const version_pk_monday = ref(Number(import.meta.env.VITE_VERSION_PK_AMPCO_MONDAY))
const version_pk_tuesday = ref(Number(import.meta.env.VITE_VERSION_PK_AMPCO_TUESDAY))

const title = ref("Ampco Flashlight Seminars")


const versionOptions = [
  { key: 0, value: '-- Kies een dag --', disabled: true},
  { key: version_pk_monday.value, value: 'Maandag 10 maart', disabled: false },
  { key: version_pk_tuesday.value, value: 'Dinsdag 11 maart', disabled: false },
]

useHead({
    title: title,
    link: [
        {
            rel: 'icon',
            type: 'image/png',
            href: favicon
        }
    ]
})

onMounted(() => {
    initFlowbite();
})

const formLabels = ref({
    first_name: "Voornaam",
    last_name: "Achternaam",
    phone: "Telefoonnummer",
    phone_sub_text: "We versturen alle updates over het evenement en jouw toegangsbewijzen via WhatsApp. Zo kunnen we je snel verder helpen en heb je jouw tickets altijd direct bij de hand. Vul hieronder je mobiele telefoonnummer in:",
    phone_under_sub_text: "Geen zorgen, je nummer wordt uitsluitend gebruikt om je te ondersteunen en jouw tickets toe te sturen.",
    email: "E-mail",
    email_sub_text: "Mochten we je via WhatsApp niet kunnen bereiken, laat dan hier jouw e-mailadres achter.",
    tickets: "Aantal personen",
    legal_text_one: "Ik ga akkoord met de ",
    legal_text_two: "algemene voorwaarden",
    legal_link: "https://livecrowd.com/privacy/",
    submit_text: "Aanmelden",
    livecrowd_help_text: "Neem contact op met Livecrowd",
    livecrowd_help_link: "https://livecrowd.com/support/",
    registered_text: "Bedankt voor jouw inschrijving. Je krijgt bericht als je bent aangemeld."
})

const formState = ref({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    number_of_tickets: 1,
    number_of_parking_tickets: 0,
    comments: "",
    version: 0,
});

const validationErrors = ref({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    number_of_tickets: '',
    number_of_parking_tickets: '',
    version: '',
    comments: ''
});

const formSchema = z.object({
    first_name: z.string().min(2, { message: 'Voornaam is verplicht en 2 tekens lang minimaal' }),
    last_name: z.string().min(2, { message: 'Achternaam is verplicht en 2 tekens lang minimaal' }),
    email: z.string().email({ message: 'Verkeerd e-mailadres' }),
    phone: z.string().optional().refine(
        value => !value || /^\+\d{10,15}$/.test(value),
        { message: 'Telefoonnummer moet een geldig internationaal formaat hebben, zoals +31688888888' }
    ),
    number_of_tickets: z.number().min(1, { message: 'Aantal tickets moet minstens 1 zijn' }),
    comments: z.string().max(512, { message: 'Opmerkingen mogen maximaal 512 tekens bevatten' }).optional(),
    version: z.number().min(1, { message: 'Versie kiezen is verplicht' }),
});


onBeforeUnmount(() => {
    document.body.style = '';
});

// Function to clear validation errors
const clearValidationErrors = () => {
    validationErrors.value = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        number_of_tickets: '',
        number_of_parking_tickets: ''
    };
};

const formRequest = async () => {
    try {
        clearValidationErrors();
        const result = formSchema.safeParse({
            ...formState.value,
        });

        if (!result.success) {
            result.error.issues.forEach(issue => {
                validationErrors.value[issue.path[0]] = issue.message;
            });
            return;
        }

        const formData = new FormData();
        formData.append('first_name', formState.value.first_name);
        formData.append('last_name', formState.value.last_name);
        formData.append('email', formState.value.email);
        formData.append('phone', formState.value.phone);
        formData.append('number_of_tickets', formState.value.number_of_tickets);
        formData.append('number_of_parking_tickets', formState.value.number_of_parking_tickets);
        formData.append('version', formState.value.version);
        formData.append('comments', formState.value.comments);

        const response = await fetch(
            `${baseUrl.value}/api/applicant/create/`,
            {
                method: 'POST',
                body: formData,
                mode: 'cors',
            },
        );
        const data = await response.json();
        formResponse.value = data;
    } catch (error) {
        console.error("Form submission failed:", error);
        return error;
    }
};

</script>
