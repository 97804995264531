<template>
    <div class="w-screen h-full flex flex-col justify-center items-center text-white py-6 md:py-0">
        <div v-if="videoUrl" id="video-background-wrapper" class="video-background-wrapper">
            <div class="fullscreen-video-wrapper">
                <div class="overlay-loader"></div>
                <div class="vimeo-wrapper">
                    <video :src="videoUrl" autoplay loop muted playsinline>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
        <section v-if="invitee && invitee.event.status !== 'active'">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
                <div class="z-10 w-full bg-white text-black rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                        <a v-if="eventTemplateData" :href="'mailto:' + eventTemplateData['locked_text_link']"
                            class="font-semibold leading-normal text-gray-700 dark:text-white">
                            {{ eventTemplateData['locked_text_subject'] }}
                        </a>

                        <hr class=" h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700">
                        <a href="https://api.whatsapp.com/send?phone=31638882552"
                            class="flex justify-center items-center hover:underline hover:text-white">
                            <div v-if="eventTemplateData" :style="{ color: eventTemplateData['locked_link_color'] }">
                                <div v-html="eventTemplateData['locked_text_body']"></div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section v-else-if="invitee"
            class="flex flex-col justify-center items-center w-full h-full overflow-auto  md:min-h-screen">
            <a v-bind:href="'?ref=' + refParam" class="z-50 flex items-center mt-4 mb-6 text-2xl font-semibold text-white">
                <img class="w-48 mr-2" :src="eventTemplateData['event_logo']" alt="Logo">
            </a>
            <div class="z-10 w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 p-4">
                <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                    <h2
                        class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
                        {{ selectedVersion.json_template['form-title'] }}
                    </h2>
                    <p class="text-black dark:text-white text-sm text-center">
                        {{ selectedVersion.json_template['help_text'] }}
                    </p>
                    <div v-if="formResponse.status">
                        <div class="flex flex-col">
                            <FwbAlert type="info" title="" class="mb-2"><p class="text-center border border-blue-500 rounded-lg py-2.5 px-4">{{ formResponse.status }}</p></FwbAlert>
                            <div class="flex flex-col">
                            <p class="text-xs text-white">
                                {{ selectedVersion.json_template['neem_contact_op_help_text'] }}
                            </p>
                            <a type="button" href="https://livecrowd.com/support/" target="_blank"
                                class="w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded text-sm py-2.5 text-center">{{ selectedVersion.json_template['neem_contact_op'] }}</a>
                        </div>
                        </div>
                    </div>
                    <form v-else class="space-y-4 md:space-y-6" @submit.prevent="formRequest">
                        <div>
                            <div id="tooltip_email" role="tooltip"
                                class="absolute text-xs z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                {{ selectedVersion.json_template['tooltip'] }}
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                            <label for="email"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ selectedVersion.json_template['email'] }}</label>
                            <input type="email" name="email" id="email" data-tooltip-target="tooltip_email"
                                class="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-600 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300"
                                placeholder="name@company.com" required="" v-model="invitee.email" disabled>
                        </div>

                        <div class="w-full flex gap-4">
                            <div>
                                <div id="tooltip_firstname" role="tooltip"
                                    class="absolute text-xs z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    {{ selectedVersion.json_template['tooltip'] }}
                                    <div class="tooltip-arrow" data-popper-arrow></div>
                                </div>
                                <label for="first_name"
                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{
                                        selectedVersion.json_template['first_name'] }}</label>
                                <input type="text" id="first_name" disabled data-tooltip-target="tooltip_firstname"
                                    class="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="John" v-model="invitee.first_name" required>
                            </div>
                            <div>
                                <div id="tooltip_lastname" role="tooltip"
                                    class="absolute text-xs z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                    {{ selectedVersion.json_template['tooltip'] }}
                                    <div class="tooltip-arrow" data-popper-arrow></div>
                                </div>
                                <label for="last_name"
                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{
                                        selectedVersion.json_template['last_name'] }}</label>
                                <input type="integer" id="last_name" disabled data-tooltip-target="tooltip_lastname"
                                    class="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Doe" v-model="invitee.last_name" required>
                            </div>
                        </div>
                        <div v-if="selectedVersion.json_template['phone']">
                            <FormInput
                                id="phone"
                                :label="selectedVersion.json_template['phone']"
                                :explanation_first="selectedVersion.json_template['phone_help_text']"
                                v-model="formState.phone"
                                :error="validationErrors.phone"
                                placeholder="+31688888888"
                                type="tel"
                            />
                        </div>
                        <div :class="[ 'mb-4', validationErrors.comments ? 'pl-2 border-red-500 border-l' : '']">
                            <label for="comments"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ selectedVersion.json_template['comments'] }}</label>
                            <textarea type="comments" name="comments" id="comments"
                                :class="[
                                    'bg-gray-50 min-h-[150px] text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white',
                                    validationErrors.comments ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                ]"
                                placeholder="Important Information" v-model="formState.comments" />
                            <p v-if="validationErrors.comments" class="text-red-500 text-sm">{{ validationErrors.comments }}</p>
                        </div>
                        <div class="flex items-center mr-4">
                            <input v-model="isCheckboxChecked" id="terms" aria-describedby="terms" type="checkbox"
                                class="accent-purple-600 w-4 h-4 text-purple-500 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                >
                            <label for="terms" class="ml-3 text-sm font-light text-gray-500 dark:text-gray-300">{{
                                eventTemplateData['legal_text_one'] }}<a
                                    class="font-medium text-primary-600 hover:underline dark:text-primary-500 text-sm"
                                    :href="eventTemplateData['legal_link']">{{ eventTemplateData['legal_text_two'] }}</a>
                            </label>
                        </div>
                        <div>
                            <button class="w-full" type="submit" :disabled="!isCheckboxChecked" :class="[
                                'text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center',
                                !isCheckboxChecked ? 'opacity-50 cursor-not-allowed' : ''
                            ]" :style="{
                                background: `linear-gradient(to right, ${selectedVersion.json_template['aanmelden_from_color']}, ${selectedVersion.json_template['aanmelden_to_color']})`,
                                color: 'white',
                                borderRadius: '0.25rem'
                            }">{{ selectedVersion.json_template['submit_text'] }}</button>
                        </div>
                        <div class="flex flex-col mt-6">
                            <p class="text-xs text-white mb-2">
                                {{ selectedVersion.json_template['neem_contact_op_help_text'] }}
                            </p>
                            <a type="button" href="https://livecrowd.com/support/" target="_blank"
                                class="w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded text-sm py-2.5 text-center">{{ selectedVersion.json_template['neem_contact_op'] }}</a>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <section v-else>
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
                <div class="z-10 w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8 text-white">
                        <h1 class="font-medium text-primary-600 text-gray-400">
                            We couldn't find an invitation link<br><a
                                class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                href="https://api.whatsapp.com/send?phone=31638882552">Please reach out if this
                                is
                                unexpected</a>
                        </h1>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import FormInput from './FormInput.vue';
import { initFlowbite } from 'flowbite'
import { useHead } from '@vueuse/head'
import { FwbAlert } from 'flowbite-vue'
import { z } from 'zod';

const refParam = ref('');
const invitee = ref(null);
const eventTemplateData = ref('');
const isLoading = ref(true);
const baseUrl = ref(import.meta.env.VITE_BASE_URL);
const videoUrl = ref('');
const selectedVersion = ref("");
const isCheckboxChecked = ref(false);
const formResponse = ref("");
const showAlertTimeout = ref("");

const title = ref("Crafture")
const favicon = ref("")

const queryParams = new URLSearchParams(window.location.search)
const refValue = queryParams.get('ref')
refParam.value = refValue;

useHead({
    title: title,
    link: [
        {
            rel: 'icon',
            type: 'image/png',
            href: favicon
        }
    ]
})

onMounted(() => {
    initFlowbite();
    fetchInvitee();
})

onBeforeUnmount(() => {
    document.body.style = '';
});

const formState = ref({
    phone: "",
    comments: "",
});

const validationErrors = ref({
    phone: '',
    comments: '',
});

const formSchema = z.object({
  phone: z.string().optional().refine(
    value => !value || /^\+\d{10,15}$/.test(value),
    { message: 'Telefoonnummer moet een geldig internationaal formaat hebben, zoals +31688888888' }
  ),
  comments: z.string().min(1 ,{ message: 'De dieetwensen zijn verplicht om in te vullen' }).max(512, { message: 'Opmerkingen mogen maximaal 512 tekens bevatten' })
});

const fetchInvitee = async () => {
    try {
        const response = await fetch(`${baseUrl.value}/api/attendee/?ref=${refParam.value}`);
        if (response.status != 200)
            return
        const data = await response.json();
        invitee.value = data;
        formState.value.phone = data.phone;
        // formState.value.comments = data.comments;
        eventTemplateData.value = data.event.json_template
        title.value = data.event.display_name
        favicon.value = baseUrl.value + data.event.favicon
        videoUrl.value = data.event.json_template.video_src

        let versionsArray = Array.isArray(invitee.value.versions) ? invitee.value.versions : [invitee.value.versions];

        // Now you can safely access the first element if it exists
        if (versionsArray.length === 1) {
            selectedVersion.value = versionsArray[0];
        }

    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

const clearValidationErrors = () => {
  validationErrors.value = {
    comments: '',
    phone: '',
  };
};

const formRequest = async () => {
    try {
        clearValidationErrors();
        const result = formSchema.safeParse({
            ...formState.value,
        });

        if (!result.success) {
            result.error.issues.forEach(issue => {
                validationErrors.value[issue.path[0]] = issue.message;
            });
            return;
        }
        const formData = new FormData();
        if (invitee.value.phone)
            formData.append('phone', formState.value.phone);
        formData.append('comments', formState.value.comments);

        const response = await fetch(
            `${baseUrl.value}/api/update_attendee/${invitee.value.uuid}/`,
            {
                method: 'PATCH',
                body: formData,
                mode: 'cors',
            },
        );
        const data = await response.json();
        console.log(data)
        formResponse.value = data;

        if (showAlertTimeout.value) {
            clearTimeout(showAlertTimeout.value);
        }

        showAlertTimeout.value = setTimeout(() => {
            formResponse.value = "";
        }, 4000);

    } catch (error) {
        console.error("Form submission failed:", error);
        return error;
    }
};

</script>
